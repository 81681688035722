import React from 'react';
import ContactForm from './Contact-form';
import Navbar from '../global/Navbar.jsx';
import Footer from '../global/Footer.jsx';
import Newsletter from '../global/Newsletter.jsx';
import Spacer from '../global/Spacer.jsx';
import Schedule from './Schedule.jsx';

function Contact() {
  return (
    <div>
      <Navbar />
      <Spacer size='10rem' />
      <Schedule />
      <ContactForm />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Contact;
