import React, { useState, useEffect } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation, Link } from 'react-router-dom';
import '../../global/globals.css'

const navigation = [
  { name: 'Uploads', href: '/uploads-client' },
  { name: 'Documents', href: '/documents-client' },
  { name: 'Messages', href: '/messages-client' },
];

const userNavigation = [
  { name: 'Settings', href: '/setting' },
  { name: 'Sign out', href: '/sign-out' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Placeholder for fetching user data from database
const getUserData = () => {
  return {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl: null, // Set to null or remove this property if using a generic icon
  };
};

export default function Example() {
    const [user, setUser] = useState(null);
    const [pageTitle, setPageTitle] = useState('Dashboard');
    const location = useLocation();
  
    useEffect(() => {
      const userData = getUserData();
      setUser(userData);
    }, []);
  
    useEffect(() => {
      const currentNavItem = navigation.find((item) => item.href === location.pathname);
      setPageTitle(currentNavItem ? currentNavItem.name : 'Dashboard');
    }, [location.pathname]);
  
    if (!user) return <div>Loading...</div>;

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white">
          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:py-2 lg:px-32">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      alt="SOF"
                      src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/Favicon/Wordpress+Transparent.png"
                      className="h-14 w-14"
                  />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href ? 'bg-blue-600 text-white shadow-md' : 'text-blue-600 hover:bg-blue-600 hover:text-white',
                          'rounded-md px-3 py-2 text-md font-medium'
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <MenuButton
                        className="relative flex max-w-xs items-center text-sm rounded-full bg-white text-blue-600 shadow-md hover:text-white hover:bg-blue-600 p-1 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        {user.imageUrl ? (
                          <img alt="" src={user.imageUrl} className="h-10 w-10 rounded-full" />
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-10 w-10">
                            <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
                          </svg>
                        )}
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          <a
                            href={item.href}
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                          >
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-blue-600 p-1 text-white hover:bg-white hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="md:hidden rounded-lg border border-gray-300">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as={Link}
                  to={item.href}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-white text-blue-600' : 'text-blue-600 hover:bg-blue-600 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium',
                  )}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
            <div className="border-t border-gray-200 w-11/12 mx-auto"></div>
            <div className="border-t border-white pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  {user.imageUrl ? (
                    <img alt="" src={user.imageUrl} className="h-10 w-10 rounded-full" />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-10 w-10 text-blue-600">
                      <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
                    </svg>
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-blue-600">{user.name}</div>
                  <div className="text-sm font-medium leading-none text-blue-600">{user.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as={Link}
                    to={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-blue-600 hover:bg-blue-600 hover:text-white"
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </div>
          </DisclosurePanel>

        </Disclosure>
        <div className="border-t-2 border-blue-100 w-9/12 mx-auto"></div>
        <header className="bg-white shadow-md">
          <div className="mx-auto max-w-7.5xl px-6 py-4 sm:px-6 lg:px-32">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{pageTitle}</h1>
          </div>
        </header>
        <main>
        </main>
      </div>
    </>
  );
}
