const navigation = [
  {
    name: 'Facebook',
    href: '#',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Linkedin',
    href: '#',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M4.98 3.5C4.98 2.12 6.1 1 7.48 1s2.5 1.12 2.5 2.5S8.85 6 7.48 6A2.49 2.49 0 0 1 4.98 3.5zM2 22h5V9H2v13zm7.83-13H22v13h-5v-6.5c0-1.79-1.46-3.25-3.25-3.25S10.5 16.21 10.5 18V22h-5V9h4.33v1.5h.08c.6-1.15 2.08-2.3 4.09-2.3C18.79 8.2 22 11.25 22 15.5V22h-.02H22v-.02V22h-.01H22v-9.18c0-4.25-3.45-7.7-7.7-7.7-1.93 0-3.76.75-5.13 2.08h-.09V9H2v-.5h5V9z" />
      </svg>
    ),
  },
]

export default function Example() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" className="h-6 w-6" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024 SOF Financial Services. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
