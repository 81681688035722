import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home/Home.jsx';
import Contact from './components/contact/Contact.js';
import Login from './components/auth/Login.js';
import About from './components/about/About.js';
import './components/global/globals.css';
import PrivacyPolicy from './components/global/Privacy.jsx';
import NewsletterForm from './components/global/Newsletter.jsx';
import ContactForm from './components/contact/Contact-form.jsx';
import Portal from './components/portal-client/Portal';
import ChangePasswordToken from './components/auth/ChangePassword_Token.js';
import ChangePassword from './components/auth/ChangePassword.js';
import ResetPassword from './components/auth/ResetPassword.js';
import Documents from './components/portal-client/documents/Documents.js';
import Messages from './components/portal-client/messages/Messages.js';
import Uploads from './components/portal-client/uploads/Uploads.js';

function App() {
    return (
        <div className='global-margin'>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/newsletter" element={<NewsletterForm />} />
                <Route path="/contact-form" element={<ContactForm />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password-form" element={<ResetPassword />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/reset-password/:token" element={<ChangePasswordToken />} />
                <Route path="/portal/*" element={<Portal />}>
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="documents" element={<Documents />} />
                    <Route path="messages" element={<Messages />} />
                    <Route path="uploads" element={<Uploads />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
