export default function Example() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-16 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Schedule a Meeting with Us
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Boost your productivity by connecting with our experts. Schedule a meeting today and let's discuss how we can help you achieve your goals.
          </p>
          <div className="mt-10 flex justify-center gap-x-6">
            <a
              href="https://calendly.com/soffinancialservices-info"
              className="rounded-3xl bg-blue-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Schedule a Meeting
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
