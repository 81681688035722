import React from 'react';
import Navbar from '../global/Navbar.jsx';
import Footer from '../global/Footer.jsx';
import Newsletter from '../global/Newsletter.jsx';
import Spacer from '../global/Spacer.jsx';
import Company from './Company.jsx';
import Team from './Team.jsx';
import Content from './Content.jsx';


function Contact() {
  return (
    <div>
      <Navbar />
      <Spacer size='10rem' />
      <Company />
      <Content />
      <Team />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Contact;
