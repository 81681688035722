const documents = [
  { id: 1, name: '2022 Tax Return', publisher: 'User', description: 'Preparation and filing of federal and state tax returns for the year 2022.', date: '2023-01-01', category: 'Tax Planning and Preparation' },
  { id: 2, name: 'Q1 Financial Report', publisher: 'System', description: 'Comprehensive financial statements for the first quarter of 2023, including income statement, balance sheet, and cash flow statement.', date: '2023-01-15', category: 'Financial Statements' },
  { id: 3, name: 'Employee Payroll 2023-06', publisher: 'System', description: 'Detailed payroll records and tax calculations for June 2023.', date: '2023-06-01', category: 'Payroll and Payroll Tax Assistance' },
  { id: 4, name: 'Vendor Invoice 2023-07', publisher: 'System', description: 'Invoice received from vendor in July 2023, including details of goods and services provided.', date: '2023-07-10', category: 'Bill Payment' },
  { id: 5, name: 'Bank Reconciliation 2023-03', publisher: 'System', description: 'Reconciliation of bank statements for March 2023 to ensure accuracy and consistency with accounting records.', date: '2023-03-20', category: 'Account Reconciliation' },
  { id: 6, name: 'Business Plan 2023', publisher: 'System', description: 'Comprehensive business plan for 2023, outlining company strategy, financial projections, and market analysis.', date: '2023-04-01', category: 'Business Plan Preparations' },
  { id: 7, name: 'Audit Representation 2023', publisher: 'System',description: 'Representation and support during IRS audit process for 2023 fiscal year.', date: '2023-08-25', category: 'IRS Audit Representation' },
  { id: 8, name: 'Client Invoice 2023-05', publisher: 'System',description: 'Invoice issued to client in May 2023 for services rendered, including payment terms and due dates.', date: '2023-05-05', category: 'Invoicing and Collections' },
  { id: 9, name: 'Monthly Bookkeeping 2023-09', publisher: 'System', description: 'Monthly bookkeeping report for September 2023, including transaction details and financial summaries.', date: '2023-09-30', category: 'Monthly Bookkeeping' },
  { id: 10, name: 'Controllership Report 2023', description: 'Annual controllership report for 2023, summarizing internal financial controls and procedures.', date: '2023-02-18', category: 'Controllership' },
  { id: 11, name: 'Q2 Financial Report', publisher: 'System',description: 'Comprehensive financial statements for the second quarter of 2023, including income statement, balance sheet, and cash flow statement.', date: '2023-04-15', category: 'Financial Statements' },
  { id: 12, name: 'Annual Budget 2023', publisher: 'User', description: 'Detailed annual budget for 2023, outlining projected revenues and expenses.', date: '2023-01-05', category: 'Budgeting and Forecasting' },
  { id: 13, name: 'Tax Advisory 2023', publisher: 'System',description: 'Expert tax advisory services for the fiscal year 2023, including tax-saving strategies and compliance advice.', date: '2023-03-10', category: 'Tax Advisory' },
  { id: 14, name: 'Vendor Payment 2023-02', publisher: 'User', description: 'Payment made to vendor in February 2023, including details of the transaction and payment method.', date: '2023-02-15', category: 'Bill Payment' },
  { id: 15, name: 'Investment Portfolio 2023', publisher: 'System',description: 'Report on investment portfolio performance for 2023, including asset allocation and returns.', date: '2023-06-20', category: 'Investment Management' },
  { id: 16, name: 'Quarterly Tax Estimate Q1 2023', publisher: 'User', description: 'Estimated quarterly tax payments for Q1 2023, including calculations and payment details.', date: '2023-03-31', category: 'Tax Planning and Preparation' },
  { id: 17, name: 'Expense Report 2023-05', publisher: 'User', description: 'Employee expense report for May 2023, detailing business-related expenses and reimbursements.', date: '2023-05-25', category: 'Expense Management' },
  { id: 18, name: 'Sales Tax Filing 2023-07', publisher: 'System',description: 'Sales tax filing for July 2023, including collected tax amounts and filed returns.', date: '2023-07-20', category: 'Sales Tax Compliance' },
  { id: 19, name: 'Cash Flow Analysis 2023', publisher: 'User', description: 'Analysis of cash flow for 2023, including inflows, outflows, and net cash position.', date: '2023-08-15', category: 'Cash Flow Management' },
  { id: 20, name: 'Year-End Financial Statements 2022', publisher: 'System',description: 'Year-end financial statements for 2022, including income statement, balance sheet, and cash flow statement.', date: '2023-01-10', category: 'Financial Statements' },
  { id: 21, name: 'Credit Card Reconciliation 2023-04', publisher: 'User', description: 'Reconciliation of credit card statements for April 2023 to ensure accuracy with accounting records.', date: '2023-04-30', category: 'Account Reconciliation' },
  { id: 22, name: 'Payroll Tax Filing Q2 2023', publisher: 'User', description: 'Filing of payroll taxes for the second quarter of 2023, including federal and state tax submissions.', date: '2023-07-15', category: 'Payroll and Payroll Tax Assistance' },
  { id: 23, name: 'Business Loan Application 2023', publisher: 'System',description: 'Application for a business loan in 2023, including financial statements and business plan.', date: '2023-03-05', category: 'Business Financing' },
  { id: 24, name: 'Tax Return Amendment 2021', publisher: 'User', description: 'Amendment of 2021 tax return to correct errors and omissions.', date: '2023-02-28', category: 'Tax Planning and Preparation' },
  { id: 25, name: 'Vendor Contract 2023', publisher: 'System',description: 'Contract with a vendor for 2023, detailing terms and conditions of the agreement.', date: '2023-01-20', category: 'Contract Management' },
  { id: 26, name: 'Depreciation Schedule 2023', publisher: 'System',description: 'Depreciation schedule for fixed assets in 2023, including calculation of depreciation expense.', date: '2023-05-10', category: 'Fixed Asset Management' },
  { id: 27, name: 'Financial Audit 2023', publisher: 'System',description: 'Independent financial audit of the company\'s financial statements for 2023.', date: '2023-09-01', category: 'Audit and Assurance' },
  { id: 28, name: 'Tax Planning Strategy 2023', publisher: 'System',description: 'Tax planning strategies for 2023 to minimize tax liability and maximize tax benefits.', date: '2023-02-05', category: 'Tax Planning and Preparation' },
  { id: 29, name: 'Retirement Plan Review 2023', publisher: 'System',description: 'Review of the company\'s retirement plan for 2023, including contributions and investment performance.', date: '2023-06-25', category: 'Retirement Planning' },
  { id: 30, name: 'Accounts Receivable Aging Report 2023-06', publisher: 'System',description: 'Aging report for accounts receivable in June 2023, detailing outstanding invoices and collection status.', date: '2023-06-10', category: 'Invoicing and Collections' },
  ];
  
  export default documents;
  