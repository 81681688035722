import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../global/Navbar';
import Footer from '../global/Footer';
import ChangePasswordFormToken from './ChangePasswordForm_Token';
import Spacer from '../global/Spacer';

function ChangePasswordToken() {
  const { token } = useParams(); 

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Spacer size="10rem" />
      <div className="flex-grow">
        <ChangePasswordFormToken token={token} />
      </div>
      <Footer />
    </div>
  );
}

export default ChangePasswordToken;
