import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export default function LoginDetails() {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handlePrimaryLogin = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const rememberMe = event.target['remember-me'].checked;

    fetch(`${API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, rememberMe }),
      credentials: 'include', 
    })
    .then(response => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json(); 
      } else {
        throw new Error('Unexpected response format');
      }
    })
    .then(data => {
      if (data.message === 'Login successful') {
        console.log('Primary login successful:', data);
        setErrorMessage('');
        navigate('/portal/home');
      } else if (data.challenge === 'NEW_PASSWORD_REQUIRED') {
        console.log('New password required:', data);
        navigate('/change-password', { state: { session: data.session, email } });
      } else {
        setErrorMessage('Invalid email or password');
      }
    })
    .catch(error => {
      if (error.message === 'Unexpected response format') {
        setErrorMessage('Server is currently unavailable. Please try again later.');
      } else {
        setErrorMessage(error.message || 'An error occurred. Please try again.');
      }
      console.error('Error:', error);
    });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-16 lg:px-8">

      <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-700">
        Sign in to your account
      </h2>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {errorMessage && (
          <div className="mb-4 text-red-500 text-center">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handlePrimaryLogin} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-700">
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                className="block w-full rounded-lg border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-md sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-700">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                className="block w-full rounded-lg border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-md sm:leading-6"
              />
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded-md border-gray-400 text-blue-600 focus:ring-blue-600"
              />
              <label htmlFor="remember-me" className="ml-3 block text-sm font-semibold leading-6 text-gray-700">
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <p className="font-semibold text-gray-700">
                <a href="/reset-password-form" className="font-semibold text-blue-600 hover:text-blue-500">Forgot password?</a>
              </p>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-3xl bg-blue-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
