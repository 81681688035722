import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

export default function DualDropDown({ selected, setSelected, options = [] }) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <Label className="sr-only">Change published status</Label>
      <div className="relative">
        <div className="inline-flex divide-x divide-blue-500 rounded-md shadow-md sm:shadow-lg w-full sm:w-48">
          <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-blue-600 px-3 py-2 text-white shadow-sm flex-grow">
            <CheckIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            <p className="text-sm font-semibold truncate">{selected}</p>
          </div>
          <ListboxButton className="inline-flex items-center justify-center rounded-l-none rounded-r-md bg-blue-600 p-2 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-50 w-10">
            <span className="sr-only">Change published status</span>
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-white" />
          </ListboxButton>
        </div>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 w-full sm:w-48 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg sm:shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {options.map((option) => (
            <ListboxOption
              key={option}
              value={option}
              className="group cursor-default select-none p-4 text-sm text-blue-600 font-semibold bg-white data-[focus]:bg-blue-600 data-[focus]:text-white"
            >
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <p className="font-semibold group-data-[selected]:font-semibold truncate">{option}</p>
                  <span className="text-blue-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                </div>
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
