const messages = [
  {
    id: 1,
    subject: 'Missing Tax Documents',
    sender: 'John Doe',
    email: 'john.doe@example.com',
    recipient: 'finance@example.com',
    body: 'I need the tax documents for Q4.',
    date: '2023-01-01T08:30:00Z',
    attachments: ['tax_q4.pdf'],
    thread: 1
  },
  {
    id: 2,
    subject: 'Re: Missing Tax Documents',
    sender: 'Finance Team',
    email: 'finance@example.com',
    recipient: 'john.doe@example.com',
    body: 'John, the documents are attached. Please confirm receipt.',
    date: '2023-01-01T09:00:00Z',
    attachments: ['tax_q4.pdf'],
    thread: 1
  },
  {
    id: 3,
    subject: 'Re: Missing Tax Documents',
    sender: 'John Doe',
    email: 'john.doe@example.com',
    recipient: 'finance@example.com',
    body: 'Received, thank you!',
    date: '2023-01-01T09:15:00Z',
    attachments: [],
    thread: 1
  },
  {
    id: 4,
    subject: 'Invoice Inquiry',
    sender: 'Jane Smith',
    email: 'jane.smith@example.com',
    recipient: 'accounts.payable@example.com',
    body: 'Can you provide the invoice for the last month? We need it for our records. Thanks!',
    date: '2023-01-02T09:45:00Z',
    attachments: ['invoice.pdf']
  },
  {
    id: 6,
    subject: 'Project Update',
    sender: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    recipient: 'project.manager@example.com',
    body: 'Here is the latest update on the project status. We have completed the initial phase and are moving to the next phase.',
    date: '2023-01-03T11:00:00Z',
    attachments: ['project_update.docx']
  },
  {
    id: 8,
    subject: 'Meeting Schedule',
    sender: 'Bob Brown',
    email: 'bob.brown@example.com',
    recipient: 'team@example.com',
    body: 'Please find the schedule for our upcoming meeting. The agenda will be shared soon.',
    date: '2023-01-04T14:15:00Z',
    attachments: ['meeting_schedule.ics']
  },
  {
    id: 10,
    subject: 'Contract Review',
    sender: 'Carol White',
    email: 'carol.white@example.com',
    recipient: 'legal.team@example.com',
    body: 'Could you review the attached contract and provide feedback? It is crucial for our next steps.',
    date: '2023-01-05T15:30:00Z',
    attachments: ['contract.pdf'],
    cc: ['manager@example.com']
  },
  {
    id: 12,
    subject: 'Expense Report',
    sender: 'David Black',
    email: 'david.black@example.com',
    recipient: 'finance@example.com',
    body: 'Attached is the expense report for the month. Please review it and let me know if there are any discrepancies.',
    date: '2023-01-06T16:45:00Z',
    attachments: ['expense_report.pdf'],
    thread: 2
  },
  {
    id: 13,
    subject: 'Re: Expense Report',
    sender: 'Finance Team',
    email: 'finance@example.com',
    recipient: 'david.black@example.com',
    body: 'David, the report looks good. No discrepancies found.',
    date: '2023-01-06T17:30:00Z',
    attachments: [],
    thread: 2
  },
  {
    id: 14,
    subject: 'Payroll Inquiry',
    sender: 'Eva Green',
    email: 'eva.green@example.com',
    recipient: 'hr@example.com',
    body: 'Can you confirm the payroll for this month? There seems to be a discrepancy.',
    date: '2023-01-07T17:00:00Z',
    attachments: []
  },
  {
    id: 16,
    subject: 'Bank Reconciliation',
    sender: 'Frank Blue',
    email: 'frank.blue@example.com',
    recipient: 'finance@example.com',
    body: 'Please reconcile the attached bank statement with our records. It is important that we have accurate financial information.',
    date: '2023-01-08T18:00:00Z',
    attachments: ['bank_statement.pdf']
  },
  {
    id: 18,
    subject: 'Year-End Financial Statements',
    sender: 'Grace Yellow',
    email: 'grace.yellow@example.com',
    recipient: 'finance@example.com',
    body: 'We need the year-end financial statements for our records. Please provide them at your earliest convenience.',
    date: '2023-01-09T19:15:00Z',
    attachments: []
  },

  {
    id: 20,
    subject: 'Quarterly Tax Payment',
    sender: 'Hank Violet',
    email: 'hank.violet@example.com',
    recipient: 'tax@example.com',
    body: 'Reminder: Quarterly tax payment is due next week. Please ensure that the payment is made on time.',
    date: '2023-01-10T20:30:00Z',
    attachments: []
  },
  {
    id: 22,
    subject: 'Payment Confirmation',
    sender: 'Ivy Orange',
    email: 'ivy.orange@example.com',
    recipient: 'accounts.receivable@example.com',
    body: 'Please confirm the receipt of our last payment. We need to update our records accordingly.',
    date: '2023-01-11T21:45:00Z',
    attachments: []
  },

  {
    id: 24,
    subject: 'Budget Planning',
    sender: 'Jack Gray',
    email: 'jack.gray@example.com',
    recipient: 'finance@example.com',
    body: 'Can you assist with the budget planning for the next fiscal year? We need to finalize it by the end of this month.',
    date: '2023-01-12T08:00:00Z',
    attachments: []
  },
  {
    id: 26,
    subject: 'Tax Consultation',
    sender: 'Karen Silver',
    email: 'karen.silver@example.com',
    recipient: 'tax.advisor@example.com',
    body: 'We need to schedule a consultation regarding our tax strategy. Please let me know your availability.',
    date: '2023-01-13T09:15:00Z',
    attachments: []
  },
  {
    id: 28,
    subject: 'Invoice Discrepancy',
    sender: 'Larry Gold',
    email: 'larry.gold@example.com',
    recipient: 'accounts.payable@example.com',
    body: 'There is a discrepancy in the latest invoice. Please review the details and let us know how to proceed.',
    date: '2023-01-14T10:30:00Z',
    attachments: ['discrepancy_details.pdf']
  },
  {
    id: 30,
    subject: 'Vendor Payment',
    sender: 'Mia Bronze',
    email: 'mia.bronze@example.com',
    recipient: 'accounts.payable@example.com',
    body: 'Please process the payment for the attached vendor invoice. We need to ensure timely payments.',
    date: '2023-01-15T11:45:00Z',
    attachments: ['vendor_invoice.pdf']
  }
];

export default messages;
