import React, { useState } from 'react';

const people = [
  {
    name: 'Stephen Fawehinmi',
    role: 'Founder & CEO',
    imageUrl:
      'https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/d78f21d4-bd05-48ac-a00c-90e7ef5949fd.jpeg',
    bio: (
      <>
        <p>Stephen Fawehinmi is the visionary CEO and Founder of SOF Financial Services, established in 2010. With over 30 years of experience in the financial services industry, Stephen has a wealth of expertise in financial analysis, planning, record keeping, statutory compliance, payroll, tax planning, and IRS audit representation. He has held key financial roles at prominent firms such as Bank of America, IBM, and Houghton Mifflin Co., contributing significantly to their financial health and operational efficiency.</p>
        <p>Stephen earned both his Bachelor's in Accounting and Master's in Taxation from Northeastern University. His educational background, combined with his extensive professional experience, has equipped him to lead SOF Financial Services with a focus on delivering exceptional, personalized service to clients. Over the years, he has acquired a diverse clientele, reflecting his ability to adapt and meet the varied needs of different businesses and individuals.</p>
        <p>Stephen understands the critical role of technology in modern financial management. Under his leadership, SOF Financial Services leverages cutting-edge tools and platforms to enhance service delivery and efficiency, ensuring that clients benefit from the latest advancements in the industry.</p>
        <p>Outside of his professional life, Stephen is a dedicated father of six who enjoys playing golf and tennis. His commitment to family and extracurricular activities underscores his well-rounded approach to life and work.</p>
      </>
    ),
    shortBio: "Stephen Fawehinmi is the visionary CEO and Founder of SOF Financial Services, established in 2010. With over 30 years of experience in the financial services industry, Stephen has held key financial roles at prominent firms such as Bank of America, IBM, and Houghton Mifflin Co."
  }
]

export default function Example() {
  const [showFullBio, setShowFullBio] = useState(false);

  return (
    <div className="bg-white py-24 md:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet Our Chief Executive Officer</h2>
        </div>
        <div className="mt-12 flex flex-col items-center lg:flex-row lg:justify-center lg:items-start">
          {people.map((person) => (
            <div key={person.name} className="flex flex-col items-center text-center gap-10 lg:flex-row lg:text-left lg:gap-20">
              <img 
                alt="" 
                src={person.imageUrl} 
                className="w-48 h-auto lg:w-60 lg:h-auto flex-none rounded-3xl shadow-md object-cover object-top" 
              />
              <div className="flex-auto max-w-lg">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  {showFullBio ? person.bio : person.shortBio}
                </p>
                <button
                  onClick={() => setShowFullBio(!showFullBio)}
                  className="mt-4 text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500"
                >
                  {showFullBio ? "Read less" : "Read more"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
