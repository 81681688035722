import React from 'react';
import Navbar from '../global/Navbar';
import Footer from '../../global/Footer';
import Selections from './Selections';
import Table from './Table'



function Messages() {
  return (
    <div>
      <Navbar />
      <Selections />
      <Table />
      <Footer />
    </div>
  );
}

export default Messages;
