import React, { useState } from 'react';
import TextBox from './Text_box';
import Checkbox from './Checkbox';
import Description from './Description';
import DropDown from './Drop-down';
import NumberBox from './Number_box';
import Radio from './Radio';
import { PhotoIcon } from '@heroicons/react/20/solid';

export default function UploadForm() {
  const [documentType, setDocumentType] = useState('');
  const [formValues, setFormValues] = useState({
    email: '',
    comment: '',
    accountNumber: '',
    plan: 'small',
    notifications: {
      comments: false,
      candidates: false,
      offers: false,
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, notifications: { ...formValues.notifications, [name]: checked } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process form submission
    console.log('Form submitted', formValues);
  };

  const documentOptions = [
    'Tax Return',
    'Financial Report',
    'Income Statement',
    'Business Expense',
  ];

  return (
    <div className="container mx-auto p-4 space-y-6">
      <div className="bg-white shadow sm:rounded-lg p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="mb-4">
            <DropDown
              selected={documentType}
              setSelected={setDocumentType}
              options={documentOptions}
            />
          </div>
          <TextBox
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
          />
          {documentType === 'Tax Return' && (
            <Description
              label="Description"
              name="comment"
              value={formValues.comment}
              onChange={handleInputChange}
            />
          )}
          {documentType === 'Financial Report' && (
            <NumberBox
              label="Account Number"
              name="accountNumber"
              value={formValues.accountNumber}
              onChange={handleInputChange}
            />
          )}
          {documentType === 'Income Statement' && (
            <Radio
              name="plan"
              selected={formValues.plan}
              onChange={handleInputChange}
            />
          )}
          <Checkbox
            notifications={formValues.notifications}
            onChange={handleCheckboxChange}
          />
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            Upload Document
          </button>
        </form>
      </div>
    </div>
  );
}
