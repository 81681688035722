import React from 'react';
import Navbar from '../global/Navbar';
import Footer from '../global/Footer';
import LoginDetails from './Login-details';
import Spacer from '../global/Spacer';




function Login() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Spacer size="7rem" />
      <div className="flex-grow">
        <LoginDetails />
      </div>
      <Footer />
    </div>
  );
}

export default Login;
