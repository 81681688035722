import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

function Portal() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${API_BASE_URL}/auth/check-auth`, {
      method: 'GET',
      credentials: 'include',
    })
    .then(response => {
      if (response.status === 401) {
        navigate('/login');
      } else {
        setIsAuthenticated(true);
      }
    })
    .catch(() => {
      navigate('/login');
    });
  }, [navigate]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; 
  }

  return <Outlet />; 
}

export default Portal;
