import React from 'react';

export default function DropDown({ selected, setSelected, options }) {
  return (
    <div>
      <label htmlFor="documentType" className="block text-sm font-medium leading-6 text-gray-900">
        Document Type
      </label>
      <select
        id="documentType"
        name="documentType"
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        <option value="" disabled>Select a document type</option>
        {options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}
