import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../global/Navbar';
import Footer from '../global/Footer';
import ChangePasswordForm from './ChangePasswordForm';
import Spacer from '../global/Spacer';

function ChangePassword() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Spacer size="10rem" />
      <div className="flex-grow">
        <ChangePasswordForm />
      </div>
      <Footer />
    </div>
  );
}

export default ChangePassword;
