import React from 'react';
import PropTypes from 'prop-types';

export default function DocumentDetails({ document, onClose }) {
  if (!document) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 shadow-2xl p-4 bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg shadow-lg w-full sm:w-2/4 sm:h-3/4 p-6 overflow-y-auto">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 hover:text-gray-900">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4">{document.name}</h2>
        <div className="flex flex-col sm:flex-row justify-between">
          <p className="text-sm text-gray-900 mb-2 sm:mb-0 sm:w-3/5">{document.description}</p>
          <p className="text-sm font-semibold text-gray-900">{document.date}</p>
        </div>
        <div className="mt-4 rounded-lg overflow-hidden">
          <embed src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/f1040.pdf" type="application/pdf" width="100%" height="600px" />
        </div>
      </div>
    </div>
  );
}

DocumentDetails.propTypes = {
  document: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
