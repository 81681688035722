import React from 'react';

const features = [
  {
    name: 'Tax',
    description: "Every individual and business want to reduce taxes, but to do it correctly isn't always so simple. That's why SOF Financial Services takes a balanced and integrated approach. We focus on your total individual and business picture, not just your next return. To us, tax time is all the time. So we keep a year-round eye on federal, state, and local tax laws, including those pending. Our proactive approach to taxes sets us apart.",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="h-12 w-12">
        <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
        <path fillRule="evenodd" d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087Zm6.163 3.75A.75.75 0 0 1 10 12h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
      </svg>
    )
  },
  {
    name: 'Finance',
    description: "Navigating financial needs can be complex, but SOF Financial Services simplifies the process. We conduct comprehensive financial needs assessments, prepare detailed loan packages with in-depth financial analysis, and identify suitable financing sources tailored to your needs. Our experts guide you in presenting loan packages to potential lenders and provide support during loan negotiations, ensuring you secure the best possible terms for your business's growth and stability",
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="h-12 w-12">
        <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
        <path fillRule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clipRule="evenodd" />
        <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
      </svg>
    )
  }
]

export default function Example() {
  return (
    <div className="relative bg-white py-24 sm:py-32 lg:pt-24 lg:pb-24">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-semibold text-blue-600">Trusted Financial Solutions</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Comprehensive Financial Services for Your Peace of Mind
        </p>
        <p className="mx-auto mt-5 max-w-prose text-lg text-gray-600">
        With over 50 years of combined experience, we offer expert financial services, including tax planning, bookkeeping, payroll, and business financing. Our user-friendly portal provides seamless record management, secure file storage, and an efficient messaging center.
        </p>
        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <div className="flex-1 flex flex-col justify-between rounded-3xl bg-gradient-to-tr from-white to-gray-200 shadow-md opacity-90 px-6 pb-8 relative">
                  <div className="absolute -top-6 -left-6">
                    <span className="inline-flex items-center justify-center rounded-2xl bg-blue-600 p-4 shadow-sm">
                      {feature.svg}
                    </span>
                  </div>
                  <div>
                    <h3 className="mt-8 text-2xl font-bold leading-8 tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base leading-7 text-gray-900">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h1 className="flex items-center justify-center mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Expert Financial Management Services</h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-600 text-center justify-center lg:max-w-none lg:grid-cols-1">
            <div>
              <p>
                Our team of seasoned financial experts is dedicated to providing you with exceptional services. From accurate monthly bookkeeping to strategic tax planning and preparation, we ensure your financial operations run smoothly and efficiently.
                Our customer portal enhances your experience with secure file storage and real-time updates. Easily access important documents like W2s and 1099s, and stay informed with timely updates from our experts.
                We offer a comprehensive range of services including invoicing and collections, bill payment, and payroll management. Our detailed financial statements and account reconciliation services provide you with a clear understanding of your business performance.
                With expert IRS audit representation and professional business plan preparation, we support your growth and protect your interests. Our commitment to excellence ensures you receive the best possible outcomes.
              </p>
            </div>
          </div>
          <div className="mt-10 flex justify-center">
            <a
              href="/about"
              className="flex justify-end rounded-3xl bg-blue-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Learn More About Us
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
