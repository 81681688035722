export default function Example() {
    return (
      <div className="overflow-hidden bg-white px-6 py-16 lg:px-8 xl:py-12">
        <div className="mx-auto max-w-max lg:max-w-7xl">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="max-w-prose text-base lg:max-w-none">
              <h2 className="font-semibold leading-6 text-blue-600">How are we different?</h2>
              <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              A Better Way to Manage Your Finances
              </p>
            </div>
          </div>
          <div className="relative">
            <svg
              fill="none"
              width={404}
              height={384}
              viewBox="0 0 404 384"
              aria-hidden="true"
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden md:block md:[overflow-anchor:none]"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" width={404} height={384} />
            </svg>
            <svg
              fill="none"
              width={404}
              height={384}
              viewBox="0 0 404 384"
              aria-hidden="true"
              className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block md:[overflow-anchor:none]"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" width={404} height={384} />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                <div className="prose prose-lg prose-blue text-gray-900 lg:max-w-none">
                  <p>
                  At SOF Financial Services, we believe in transforming financial management through comprehensive and innovative solutions. Our approach is rooted in understanding the unique needs of each client, allowing us to tailor our services to fit your specific financial goals. Whether you are an individual or a business, our methodology ensures that every aspect of your financial health is addressed with precision and care.
                  </p><br></br>
                  <p>
                  Our commitment to proactive service sets us apart. We stay ahead of the curve by keeping a constant eye on evolving financial regulations and tax laws, ensuring that you are always compliant and well-prepared. Our personalized service means that we don’t just react to your needs but anticipate them, providing timely advice and support that helps you make informed financial decisions.
                  </p>
                </div>
                <div className="prose prose-lg prose-blue mt-6 text-gray-900 lg:mt-0">
                  <p>
                  In today’s fast-paced world, technology plays a crucial role in effective financial management. At SOF Financial Services, we leverage cutting-edge tools and platforms to streamline our processes and enhance service delivery. This not only improves efficiency but also ensures that our clients benefit from the latest advancements in financial technology. Our user-friendly online portal provides a seamless experience, offering easy access to essential financial tools and information.
                  </p><br></br>
                  <p>
                  With over 50 years of combined experience, our team is led by seasoned professionals who bring a wealth of knowledge and expertise to the table. At SOF Financial Services, we are dedicated to building long-term relationships with our clients, grounded in trust, expertise, and a commitment to your financial well-being.
                  </p>
                </div>
              </div>
              <div className="mt-8 inline-flex rounded-md shadow-sm">
                <a
                  href="/contact"
                  className="flex items-center justify-center rounded-3xl border border-transparent bg-blue-600 px-5 py-3 text-base font-medium text-white hover:bg-blue-700"
                >
                  Schedule Consultation
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  