import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../global/Navbar';
import Footer from '../global/Footer';
import Header from './Header';
import Featuredsection from './Featured-section.jsx';
import Newsletter from '../global/Newsletter';
import Content from './Content-public';
import Content2 from './Content-portal';
import Learnmore from './Learn-more';
import '../global/globals.css';
import Longcontentwhite from './Longcontent-white';
import Intro from './Intro.js';
import Spacer from '../global/Spacer';

function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === 'home#services') {
      const element = document.getElementById('services');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <Navbar />
      <Spacer size='5rem' />
      <Header />
      <div className="border-t border-gray-200 my-8"></div>
      <Intro />
      <div id="services" className="border-t border-gray-200 my-8"></div>
      <Content />
      <Learnmore />
      <div className="border-t border-gray-200 my-8"></div>
      <Content2 />
      <Longcontentwhite />
      <div className="border-t border-gray-200 my-8"></div>
      <Featuredsection />
      <div className="border-t border-gray-200 my-8"></div>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Home;
