import { useState } from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

export default function DropDown({ selected, setSelected, options = [] }) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative shadow-md sm:shadow-lg">
        <ListboxButton className="w-full sm:w-48 cursor-default rounded-md bg-blue-600 py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 flex items-center">
          <span className="block truncate text-sm font-semibold text-white">{selected}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-white" />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 w-full sm:w-48 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {options.map((option) => (
            <ListboxOption
              key={option}
              value={option}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-blue-600 data-[focus]:bg-blue-600 data-[focus]:text-white"
            >
              <span className="block truncate font-semibold group-data-[selected]:font-semibold">{option}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
