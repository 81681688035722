import React from 'react';
import Navbar from '../global/Navbar';
import Footer from '../../global/Footer';
import Table from './Table';




function Documents() {
  return (
    <div>
      <Navbar />
      <Table />
      <Footer />
    </div>
  );
}

export default Documents;
