import { CloudIcon, ClockIcon, UserGroupIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Cloud-based File Sharing',
    description:
      'Our platform offers secure cloud-based file sharing, ensuring your important documents like W-2s and 1099s are always accessible when you need them. With AWS security and multi-factor authentication, your data is protected.',
    href: '/contact',
    icon: CloudIcon,
  },
  {
    name: 'Real-time Updates',
    description:
      'Receive real-time updates and notifications from our expert team on the progress of your services. Stay informed with the latest data on your financial and tax matters, ensuring complete transparency.',
    href: '/contact',
    icon: ClockIcon,
  },
  {
    name: 'Expert Tax and Finance Professionals',
    description:
      'Our team of seasoned professionals offers tailored services to meet your unique business needs. From tax preparation to financial planning, we provide personalized solutions to help your business thrive.',
    href: '/contact',
    icon: UserGroupIcon,
  },
]

export default function Example() {
  return (
    <div className="relative bg-white py-12 sm:py-24">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-semibold text-blue-600">Innovative Tech Solutions
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Advanced Client Portal
        </p>
        <p className="mx-auto mt-5 max-w-prose text-lg text-gray-600">
            Experience seamless financial management with our innovative platform designed for secure file sharing, real-time updates, and expert support tailored to your business needs.
        </p>
        <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            alt="App screenshot"
            src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/Screenshot_23-7-2024_195141_localhost.jpeg"
            width={2432}
            height={1442}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          />
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-lg font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-2xl shadow-sm bg-blue-600">
                    <feature.icon aria-hidden="true" className="h-8 w-8 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
