import React, { useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';
import messages from './sample_messages.js';
import Pagination from '../global/Pagination';
import { MessageDetails } from './Message-details.jsx';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Messages() {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const messagesPerPage = 15;

  const handleRowClick = (message) => {
    setSelectedMessage(selectedMessage && selectedMessage.id === message.id ? null : message);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort =  (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedMessages = [...messages].sort((a, b) => {
    if (sortConfig.key === null) {
      return 0;
    }
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredMessages = sortedMessages.filter((message) =>
    message.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.sender.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.body.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const threadMessages = filteredMessages.reduce((acc, message) => {
    const threadId = message.thread || message.id;
    if (!acc[threadId]) {
      acc[threadId] = [];
    }
    acc[threadId].push(message);
    return acc;
  }, {});

  const latestMessages = Object.values(threadMessages).map(messages => {
    return messages.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
  });

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = latestMessages.slice(indexOfFirstMessage, indexOfLastMessage);

  const totalPages = Math.ceil(latestMessages.length / messagesPerPage);

  const getClassNamesFor = (name) => {
    if (!sortConfig.key) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-32">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-4 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-100 rounded-lg shadow-lg">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer"
                      onClick={() => handleSort('subject')}
                    >
                      <div className="flex items-center">
                        Subject
                        {getClassNamesFor('subject') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('sender')}
                    >
                      <div className="flex items-center">
                        Sender
                        {getClassNamesFor('sender') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('email')}
                    >
                      <div className="flex items-center">
                        Email
                        {getClassNamesFor('email') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('body')}
                    >
                      <div className="flex items-center">
                        Body
                        {getClassNamesFor('body') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('date')}
                    >
                      <div className="flex items-center">
                        Date
                        {getClassNamesFor('date') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentMessages.map((message) => (
                    <tr key={message.id} onClick={() => handleRowClick(message)} className="cursor-pointer shadow hover:shadow-lg">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:text-blue-600">
                        {message.subject}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {message.sender}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {message.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold max-w-xs overflow-hidden text-ellipsis">
                        {message.body}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {message.date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {selectedMessage && (
              <MessageDetails selectedMessage={selectedMessage} setSelectedMessage={setSelectedMessage} messages={messages} />
            )}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
