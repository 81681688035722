import React from 'react';
import Navbar from './Navbar.jsx';
import Footer from './Footer.jsx';
import PrivacyPolicy from './Privacypolicy.js';
import Spacer from '../global/Spacer.jsx';


function Services() {
  return (
    <div>
      <Navbar />
      <Spacer size="10rem" />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}

export default Services;
