import React from 'react';
import Navbar from '../global/Navbar';
import Footer from '../global/Footer';
import PasswordResetForm from './SendPassword-Reset';
import Spacer from '../global/Spacer';

function ResetPassword() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Spacer size="10rem" />
      <div className="flex-grow">
        <PasswordResetForm />
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassword;
