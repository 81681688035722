

export default function Example() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-blue-100/20">
        <div className="mx-auto max-w-7xl sm:pb-32 sm:pt-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-24 pt-24 pb-24">
          <div className="px-6 lg:px-0 lg:pt-48">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Financial Services for Your Peace of Mind
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Empower your financial future with SOF Financial Services. We offer bookkeeping, tax planning, 
                  payroll, and business financing solutions, ensuring comprehensive, proactive support for individuals and businesses.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/contact"
                    className="rounded-3xl bg-blue-600 px-4 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  >
                    Schedule Consultation
                  </a>
                  <a href="/contact" className="text-md font-semibold leading-6 text-blue-600 hover:text-blue-500">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-16 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-20 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-blue-800/10 ring-1 ring-blue-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-blue-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-blue-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-4 sm:pt-8 md:pl-4 md:pr-0">
                  <div className="mx-auto max-w-full md:mx-0 md:max-w-none">
                      <img
                        alt="App screenshot"
                        src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/Screenshot_23-7-2024_193757_localhost.jpeg"
                        className="w-full h-auto mt-10 translate-x-14 form shadow-lg rounded-3xl object-top object-cover object-left-top"
                      />
                  </div>
                  <div
                    className="pointer-events-none shadow-lg  absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  )
}
