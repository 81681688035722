import React from 'react';
import UploadForm from './Upload-form';

function Upload() {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Upload Documents</h1>
      <UploadForm />
    </div>
  );
}

export default Upload;
