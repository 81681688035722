import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-white py-24 sm:py-32 lg:pt-32 lg:pb-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy</h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Effective Date: [May 10th, 2024]
          </p>
          <div className="mt-6 space-y-6">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">1. Information We Collect</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                We may collect information about you in a variety of ways. The information we may collect on the Site includes:
              </p>
              <ul className="mt-2 list-disc list-inside text-base leading-7 text-gray-700">
                <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, shipping address, email address, and telephone number.</li>
                <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the Site, such as your IP address, your browser type, etc.</li>
                <li><strong>Financial Data:</strong> Financial information, such as data related to your payment method.</li>
                <li><strong>Mobile Device Data:</strong> Device information, such as your mobile device ID, model, and manufacturer.</li>
              </ul>
            </div>
            <div className="border-t border-gray-200 my-8"></div>
            <div className="border-t border-gray-200 my-8"></div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">2. Use of Your Information</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                We use information collected about you via the Site to:
              </p>
              <ul className="mt-2 list-disc list-inside text-base leading-7 text-gray-700">
                <li>Create and manage your account.</li>
                <li>Process your transactions and send you related information.</li>
                <li>Email you regarding your account or order.</li>
                <li>Provide customer support.</li>
                <li>Send you technical notices, updates, security alerts, and support and administrative messages.</li>
                <li>Communicate with you about services, offers, promotions, rewards, and events offered by SOF Financial Services.</li>
                <li>Improve the Site and optimize the user experience.</li>
                <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
              </ul>
            </div>
            <div className="border-t border-gray-200 my-8"></div>
            <div className="border-t border-gray-200 my-8"></div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">3. Disclosure of Your Information</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                We may share information we have collected about you in certain situations, such as:
              </p>
              <ul className="mt-2 list-disc list-inside text-base leading-7 text-gray-700">
                <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to protect the rights, property, and safety of others.</li>
                <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf.</li>
                <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
              </ul>
            </div>
            <div className="border-t border-gray-200 my-8"></div>
            <div className="border-t border-gray-200 my-8"></div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">4. Security of Your Information</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                We use administrative, technical, and physical security measures to help protect your personal information. However, no security measures are perfect or impenetrable.
              </p>
            </div>
            <div className="border-t border-gray-200 my-8"></div>
            <div className="border-t border-gray-200 my-8"></div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">5. Policy for Children</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                We do not knowingly solicit information from or market to children under the age of 13. If we learn we have collected personal information from a child under age 13, we will delete that information as quickly as possible.
              </p>
            </div>
            <div className="border-t border-gray-200 my-8"></div>
            <div className="border-t border-gray-200 my-8"></div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">6. Changes to This Privacy Policy</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by updating the effective date of this Privacy Policy.
              </p>
            </div>
            <div className="border-t border-gray-200 my-8"></div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">7. Contact Us</h2>
              <p className="mt-2 text-base leading-7 text-gray-700">
                If you have questions or comments about this Privacy Policy, please contact us at:
              </p>
              <p className="mt-2 text-base leading-7 text-gray-700">
                <strong>SOF Financial Services</strong><br />
                130 Edgemere Road<br />
                Lynn, MA 01904<br />
                Phone: (781) 913-2999<br />
                Email: sofawehinmi1@comcast.net<br />
                Website: <a href="https://www.soffinancialservices.com" className="text-blue-600 hover:underline">www.soffinancialservices.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
