import React, { useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import Pagination from '../global/Pagination';
import documents from './sample_documents.jsx';
import DocumentDetails from './Document-details';
import Selection from './Selections';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Documents() {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [categoryFilter, setCategoryFilter] = useState('Select a category'); // Placeholder
  const [publisherFilter, setPublisherFilter] = useState('Select a publisher'); // Placeholder
  const documentsPerPage = 10;

  const categoryOptions = ['Select a category', 'All', 'Category 1', 'Category 2', 'Category 3'];
  const publisherOptions = ['Select a publisher', 'Both', 'User', 'System'];

  const handleRowClick = (document) => {
    setSelectedDocument(selectedDocument && selectedDocument.id === document.id ? null : document);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedDocuments = [...documents].sort((a, b) => {
    if (sortConfig.key === null) {
      return 0;
    }
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredDocuments = sortedDocuments.filter((document) => {
    const matchesSearch = document.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          document.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          document.category.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = categoryFilter === 'Select a category' || categoryFilter === 'All' || document.category === categoryFilter;
    const matchesPublisher = publisherFilter === 'Select a publisher' || publisherFilter === 'Both' || document.publisher === publisherFilter;
    return matchesSearch && matchesCategory && matchesPublisher;
  });

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

  const getClassNamesFor = (name) => {
    if (!sortConfig.key) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-32">
      <Selection
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        categoryOptions={categoryOptions}
        publisherFilter={publisherFilter}
        setPublisherFilter={setPublisherFilter}
        publisherOptions={publisherOptions}
      />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-4 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-100 rounded-lg shadow-lg">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer"
                      onClick={() => handleSort('name')}
                    >
                      <div className="flex items-center">
                        Document
                        {getClassNamesFor('name') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('description')}
                    >
                      <div className="flex items-center">
                        Description
                        {getClassNamesFor('description') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('category')}
                    >
                      <div className="flex items-center">
                        Category
                        {getClassNamesFor('category') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('publisher')}
                    >
                      <div className="flex items-center">
                        Publisher
                        {getClassNamesFor('publisher') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                      onClick={() => handleSort('date')}
                    >
                      <div className="flex items-center">
                        Date
                        {getClassNamesFor('date') === 'asc' ? (
                          <ArrowUpIcon className="w-4 h-4 ml-2" />
                        ) : (
                          <ArrowDownIcon className="w-4 h-4 ml-2" />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentDocuments.map((document) => (
                    <tr key={document.id} onClick={() => handleRowClick(document)} className="cursor-pointer shadow hover:shadow-lg">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:text-blue-600">
                        {document.name}
                      </td>
                      <td className="whitespace-nowrap max-w-xs overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {document.description}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {document.category}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {document.publisher}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-blue-600 hover:font-bold">
                        {document.date}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-600 hover:text-blue-400 hover:font-bold">
                        <ArrowDownTrayIcon className="w-5 h-5 text-blue-600 font-semibold hover:text-blue-600" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <DocumentDetails
        document={selectedDocument}
        onClose={() => setSelectedDocument(null)}
      />
    </div>
  );
}
