import React, { useState } from 'react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { format, isYesterday, isThisWeek, isSameYear } from 'date-fns';

export const MessageDetails = ({ selectedMessage, setSelectedMessage, messages = [] }) => {
  const [showDetails, setShowDetails] = useState(false);

  if (!selectedMessage) {
    return null;
  }

  const formatDate = (date) => {
    const dateObj = new Date(date);
    if (isYesterday(dateObj)) {
      return `Yesterday at ${format(dateObj, 'p')}`;
    }
    if (isThisWeek(dateObj)) {
      return format(dateObj, 'EEEE p');
    }
    if (isSameYear(dateObj, new Date())) {
      return format(dateObj, 'MM/dd/yyyy');
    }
    return format(dateObj, 'PPP');
  };

  // Find all messages in the same thread
  const threadMessages = messages.filter(
    message => message.thread === selectedMessage.id || message.id === selectedMessage.id || message.thread === selectedMessage.thread
  ).sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div className="fixed inset-y-0 right-0 z-50 w-full lg:w-6/12 bg-gray-200 shadow-2xl overflow-y-auto border border-gray-300 rounded-l-lg">
      <div className="p-6">
        <div className="mt-4 bg-white rounded-lg shadow-lg p-4 flex justify-between items-center border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900">{selectedMessage.subject}</h2>
          <button
            type="button"
            onClick={() => setSelectedMessage(null)}
            className="text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        {threadMessages.map((message, index) => (
          <div key={index} className="mt-4 bg-white rounded-lg shadow-lg p-4">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-sm text-gray-1000 pb-0">
                <span className="font-semibold text-sm text-gray-1000">From</span> {message.sender}
              </p>
              <p className="font-semibold text-sm text-gray-1000 pb-1">
                {formatDate(message.date)}
              </p>
            </div>
            <div className="mt-2 text-blue-600 text-sm cursor-pointer hover:underline" onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? 'See less' : 'See more'}
            </div>
            {showDetails && (
              <div className="mt-2 text-gray-1000 text-sm leading-relaxed pt-4">
                <p><span className="font-small">From:</span> {message.sender} (<a href={`mailto:${message.email}`} className="text-blue-600 hover:underline">{message.email}</a>)</p>
                <p><span className="font-small">To:</span> {message.recipient}</p>
                {message.cc && <p><span className="font-medium">Cc:</span> {message.cc.join(', ')}</p>}
              </div>
            )}
            <p className="mt-2 text-gray-1000 text-sm leading-relaxed pt-4">{message.body}</p>
            {message.attachments.length > 0 && (
              <div className="mt-4">
                <h3 className="text-sm font-semibold text-gray-1000">attachments</h3>
                <ul className="list-disc pl-5 space-y-1 mt-2">
                  {message.attachments.map((attachment, index) => (
                    <li key={index} className="text-sm text-blue-600 hover:underline">{attachment}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
        <div className="flex items-start space-x-4 py-4">
          <div className="min-w-0 flex-1">
            <form action="#" className="relative">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-600 px-2 bg-white p-4">
                <label htmlFor="Add-recipients" className="sr-only">Add recipients</label>
                <input
                  id="Add-recipients"
                  name="Add-recipients"
                  type="email"
                  placeholder="Add recipients..."
                  className="block w-full resize-none border-0 bg-transparent py-2 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 mb-2"
                />
                <label htmlFor="comment" className="sr-only">Type your reply</label>
                <textarea
                  id="comment"
                  name="comment"
                  rows={3}
                  placeholder="Type your reply..."
                  className="block w-full resize-none border-0 bg-transparent py-2 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
                <div aria-hidden="true" className="py-2">
                  <div className="py-px">
                    <div className="h-9" />
                  </div>
                </div>
              </div>
              <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5">
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    >
                      <PaperClipIcon aria-hidden="true" className="h-5 w-5" />
                      <span className="sr-only">Attach a file</span>
                    </button>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <button
                    type="submit"
                    className="inline-flex items-center rounded-lg bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Reply
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
