import React, { useState } from 'react';
import { BarsArrowUpIcon } from '@heroicons/react/20/solid';
import DropDown from './Drop-down.jsx';
import DualDropDown from './Dual-drop-down.jsx';

export default function Selection({ searchTerm, setSearchTerm, categoryFilter, setCategoryFilter, publisherFilter, setPublisherFilter }) {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center pt-4 space-y-4 sm:space-y-0 sm:space-x-4">
      <div className="flex-grow flex items-center">
        <div className="relative flex rounded-md shadow-md w-full">
          <div className="relative flex flex-grow items-stretch focus-within:z-40">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <BarsArrowUpIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-600 hover:text-white"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex items-center">
        <DropDown selected={categoryFilter} setSelected={setCategoryFilter} options={['All', 'Category 1', 'Category 2', 'Category 3']} />
      </div>
      <div className="flex items-center">
        <DualDropDown selected={publisherFilter} setSelected={setPublisherFilter} options={['Both', 'User', 'System']} />
      </div>
    </div>
  );
}
