import React from 'react';
import { BarsArrowUpIcon } from '@heroicons/react/20/solid';

export default function Selection({ searchTerm, setSearchTerm }) {
  return (
    <div className="flex flex-col items-center sm:flex-row sm:items-center lg:px-48 pt-4 space-y-4 sm:space-y-0 sm:space-x-4">
      <div className="w-full sm:w-auto sm:flex-auto">
        <div className="flex rounded-md shadow-md">
          <div className="relative flex flex-grow items-stretch focus-within:z-40">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <BarsArrowUpIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search messages..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
          </div>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-blue-600 hover:text-white"
          >
            Search
          </button>
        </div>
      </div>
      <div className="mt-4 sm:mt-2 sm:ml-12 sm:flex-none">
        <button
          type="button"
          className="block shadow-md rounded-lg bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          New Message
        </button>
      </div>
    </div>
  );
}
