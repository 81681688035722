import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    CogIcon,
    LockClosedIcon,
    ServerIcon,
    ShieldCheckIcon,
} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Monthly Bookkeeping',
    description: 'Accurate and timely record-keeping to manage your financial transactions each month.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Financial Statements',
    description: 'Preparation of detailed financial statements to help you understand your business performance.',
    icon: LockClosedIcon,
  },
  {
    name: 'Tax Planning and Preparation',
    description: 'Strategic tax planning and preparation services to minimize liabilities and ensure compliance.',
    icon: ArrowPathIcon,
  },
  {
    name: 'IRS Audit Representation',
    description: 'Expert representation during IRS audits to protect your interests and ensure a fair outcome.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Invoicing and Collections',
    description: 'Streamlined invoicing and collections process to ensure timely payments and improved cash flow.',
    icon: CogIcon,
  },
  {
    name: 'Business Plan Preparation',
    description: 'Professional preparation of comprehensive business plans to guide your growth and secure funding.',
    icon: ServerIcon,
  },
  {
    name: 'Controllership',
    description: 'Professional oversight of your financial operations to ensure efficient and effective management.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Account Reconciliation',
    description: 'Regular reconciliation of accounts to ensure accuracy and identify any discrepancies.',
    icon: CogIcon,
  },
  {
    name: 'Bill Payment',
    description: 'Efficient bill payment services to manage your payables and maintain good vendor relationships.',
    icon: ServerIcon,
  },
]

export default function Example() {
  return (
    <div className="relative bg-white py-24 sm:pt-32 sm:pb-0 lg:pb-8 lg:py-36">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-semibold text-blue-600">Expert Financial Services</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Comprehensive Solutions for Your Business Needs
        </p>
        <p className="mx-auto mt-5 max-w-prose text-lg text-gray-600">
          Our expert team provides tailored financial services to ensure your business runs smoothly and efficiently. From accurate bookkeeping to strategic tax planning, we've got you covered.
        </p>
        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-3xl bg-gray-100 shadow-md px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-2xl bg-blue-600 p-3 shadow-sm">
                        <feature.icon aria-hidden="true" className="h-8 w-8 text-white" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base leading-7 text-gray-900">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
