import React from 'react';

export default function Checkbox({ notifications, onChange }) {
  return (
    <fieldset>
      <legend className="sr-only">Notifications</legend>
      <div className="space-y-5">
        {Object.keys(notifications).map((key) => (
          <div key={key} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={key}
                name={key}
                type="checkbox"
                checked={notifications[key]}
                onChange={onChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor={key} className="font-medium text-gray-900">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
