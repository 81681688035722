import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export default function PasswordResetForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [frontendUrl, setFrontendUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.origin;
    sessionStorage.setItem('frontend_url', url);
    setFrontendUrl(url); 
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(`${API_BASE_URL}/auth/reset_password_request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, frontendUrl }), 
    })
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
        setEmail('');
        navigate('/login');
      })
      .catch(error => {
        console.error('Error:', error);
        setMessage('An error occurred. Please try again.');
      });
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto mt-10 pt-32">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-3xl font-bold tracking-tight text-gray-700">
            Reset Your Password
          </h2>
          <p className="mt-1 text-sm text-gray-600">
            Enter your email address below and we'll send you instructions to reset your password.
          </p>

          <div className="mt-6">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
                className="block w-full rounded-md border border-gray-300 px-5 py-3 text-base text-gray-700 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>
          {message && (
        <p className="mt-6 text-center font-semibold text-sm border rounded-md bg-blue-50 text-gray-800">
          {message}
        </p>
      )}
        </div>
      </div>
      
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Send Reset Instructions
        </button>
      </div>
    </form>
  );
}
