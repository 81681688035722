export default function Example() {
  return (
    <div className="bg-white py-24 sm:pt-32 sm:pb-2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <p className="text-base font-semibold leading-7 text-blue-600">Expert Financial Services</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comprehensive Solutions for Your Business Needs</h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-600 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>
                At SOF Financial Services, we are dedicated to providing top-tier financial services tailored to meet the unique needs of your business. Our experts specialize in accurate and timely monthly bookkeeping, ensuring your financial transactions are meticulously recorded.
              </p>
              <p className="mt-8">
                Our team of professionals offers strategic tax planning and preparation to minimize liabilities and ensure compliance. With our proactive approach, we keep a year-round eye on tax laws to provide you with the best possible outcomes.
              </p>
            </div>
            <div>
              <p>
                Our comprehensive financial statements give you clear insights into your business performance, helping you make informed decisions. Additionally, we offer expert IRS audit representation to protect your interests during audits.
              </p>
              <p className="mt-8">
                Leverage our user-friendly online portal for seamless record management, secure file storage, and real-time updates. Our platform ensures that you can access and share critical financial documents with ease, enhancing your overall financial management experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 sm:py-48 lg:py-16 lg:pb-32 lg:px-8">
        <div className="overflow-hidden rounded-3xl bg-blue-400 shadow-lg lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:self-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              <span className="block">Ready to Take Control of Your Finances?</span>
              <span className="block text-xl sm:text-2xl">Start your journey with us today.</span>
            </h2>

              <p className="mt-4 text-lg leading-6 text-blue-200">
              Unlock comprehensive financial solutions and access our user-friendly 
              portal for record management, file storage, and secure messaging. We utilize QuickBooks and TaxAct to ensure top-notch service.
              </p>
              <a
                href="/contact"
                className="mt-8 inline-flex items-center rounded-3xl border border-transparent bg-white px-5 py-3 text-base font-medium text-blue-600 shadow-sm hover:text-white hover:bg-blue-600"
              >
                Book a Demo
              </a>
            </div>
          </div>
          <div className="aspect-h-4 aspect-w-3 -mt-6 md:aspect-h-16 md:aspect-w-9">
            <img
              alt="App screenshot"
              src="https://s3.us-east-2.amazonaws.com/www.soffinancialservices.com/site-images/1040.PNG"
              className="w-full h-full transform rounded-md object-cover object-left-top sm:translate-x-12 lg:translate-y-24"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
